body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.backImageLogo {
	background-image: linear-gradient(
		rgba(41, 171, 226, 0.5),
		rgba(0, 19, 27, 0.4)
	);
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.backImageLogo img {
	width: 250px;
	background: white;
	padding: 25px 25px;
	border-radius: 10px;
	opacity: 0.9;
	animation: animates 1.1s linear infinite;
}

.backImage {
	border-radius: 10px;
	opacity: 0.9;
	animation: animate 1.1s linear infinite;
}

@keyframes animate {
	0% {
		transform: scale(1);
		box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.8);
	}
	25% {
		transform: scale(1);
		box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.8);
	}
	30% {
		transform: scale(1.2);
		box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.8);
	}
	50% {
		transform: scale(1.1);
		box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.8);
	}
	70% {
		transform: scale(1.2);
		box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.8);
	}
	90% {
		transform: scale(1);
		box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.8);
	}
	100% {
		transform: scale(1);
		box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.8);
	}
}

@media (max-width: 600px) {
	.backImage {
		display: none;
	}
}
